import {PrimaryButton, TextButton, GrayButton, LinkButton, BlueLinkButton, GreenButton, RedBorderButton, BlueButton, BlackButton, RedTextButton, GrayBorderButton, BlueBorderButton, BaseButton, OutlinedButton} from './button.styles';

export const BUTTON_TYPE_CLASSES = {
  BaseButton: 'base-button',
  OutlinedButton: 'outlined-button',
  TextButton: 'text-button',
}

const getButton = (buttonType = BUTTON_TYPE_CLASSES.BaseButton) => (
  {   [BUTTON_TYPE_CLASSES.BaseButton]: BaseButton, 
      [BUTTON_TYPE_CLASSES.OutlinedButton]: OutlinedButton, 
      [BUTTON_TYPE_CLASSES.TextButton]: TextButton,
  }[buttonType]
)

const Button = ({children, buttonType, ...props}) => {
    const CMButton = getButton(buttonType);
    
    return (
        <CMButton {...props}>{children}</CMButton>
    )
}

export default Button;