import { createContext, useContext, useEffect, useState } from "react";
import { getAuth, removeAuth, setAuth } from "../Utils/auth.utils";

const AuthContext = createContext({
    auth: getAuth(),
    saveAuth: () => {},
    currentUser: null,
    setCurrentUser: () => null,
    logout: () => {}
});

const useAuth = () => {
    return useContext(AuthContext)
}

const AuthProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(getAuth());
    const [auth, setAuthContext] = useState(getAuth());

    const saveAuth = (auth) => {
        if(auth) {
            setAuth(auth);
        } else {
            removeAuth();
        }
    }

    const logout = () => {
        saveAuth(null)
        setCurrentUser(null)
        removeAuth();
      }


    const value = {auth, saveAuth, currentUser, setCurrentUser, logout};

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const AuthInit= ({children}) => {
    const {auth, setCurrentUser, saveAuth} = useAuth();

    useEffect(()=>{
        const date = new Date().getTime();

        if(auth && auth.expireAt >= date) {
            setCurrentUser(auth.username);
        } else {
            setCurrentUser(null);
            saveAuth(null);
        }

    },[auth])

    return <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}