import { circularProgressClasses } from "@mui/material";
import { CMBox, CMProgress, CMGrayProgress } from "./loading.styles";

const Loading = () => {
    return (
        <CMBox>
            <CMGrayProgress thickness={5} size={80} value={100} variant='determinate' />
            <CMProgress color='primary' thickness={5} size={80} value={100} variant='indeterminate' disableShrink sx={{
                [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                },
                }}/>
            
        </CMBox>
    )
}

export default Loading;