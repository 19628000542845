import { useEffect, useState } from "react";
import Button from "../button/button.component";
import { CMDisclaimer } from "./cookies-disclaimer.styles";
import { acceptCookieConsent, getCookie } from "../../Utils/common.utils";

const CookiesDisclaimer = () => {
    const [visible, setVisible] = useState(false);
    const cookie_name = 'cookieconsent_status';

    useEffect(() => {
        let cookie_consent = getCookie(cookie_name);
        if(cookie_consent == ""){
            setVisible(true);
        }
    }, [])

    const acceptCookie = () => {
        acceptCookieConsent(cookie_name);
        setVisible(false);
    }

    return (
        <>
        {visible ? 
            <CMDisclaimer id="cookieNotice">
                <p>
                    This website uses cookies to ensure you get the best experience on our website.
                    <a title="Learn More" href="https://cookiesandyou.com/" rel="noopener noreferrer nofollow" target="_blank">Learn more</a>
                </p>
                <Button size="small" shape="squared" onClick={acceptCookie}>Got it!</Button>
            </CMDisclaimer> : null
        }
        </>
        
    )
}

export default CookiesDisclaimer;