import { Box, CircularProgress, styled } from "@mui/material";

export const CMGrayProgress = styled(CircularProgress)(({theme}) => ({
    marginTop: '-10%',
    marginLeft: '60px',
    color: 'gray'
}));

export const CMProgress = styled(CMGrayProgress)(({theme}) => ({
    marginTop: '-10%',
    position: 'absolute',
    animationDuration: '750ms',
    color: '#a80000'
}));

export const CMBox = styled(Box)(({theme}) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 100px - 160px)'
}));