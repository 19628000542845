import './App.css';
import MasterLayout from './Layouts/MasterLayout';
import { AuthInit } from './Contexts/auth.context';
import ScrollToTop from './components/scrolltop/scrolltop.component';

function App() {
  return (
    <AuthInit>
      <ScrollToTop/>
      <MasterLayout/>
    </AuthInit>
  );
}

export default App;
