function setAuth(user) {
    localStorage.setItem('userId', JSON.stringify(user));
}

function getAuth(){
    return JSON.parse(localStorage.getItem('userId'));
}

function removeAuth(){
    localStorage.removeItem('userId');
}

export {setAuth, getAuth, removeAuth};