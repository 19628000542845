import { createContext, useContext, useEffect, useState } from "react";
import { getListingCatalogue } from "../Services/prod/catalogue.service";
import { asset_Add, asset_Update, asset_getByEntryId, asset_getMyActiveListings } from "../Services/prod/asset.service";
import { media_addArt, media_getArtById, media_getMediaById, media_updateArt } from "../Services/prod/media.service";
import { getByAssetDetailsId, getSelectedByAssetDetails } from "../Services/prod/royalty-rights.service";
import { getByRightId } from "../Services/prod/financials.service";
import { assetDetails_Add, assetDetails_GetByEntryId, assetDetails_Update } from "../Services/prod/asset-details.service";

const CatalogueContext = createContext({
    listingCatalogue: [],
    setListingCatalogue: () => null
});

const useCatalogue = () => {
    return useContext(CatalogueContext)
}

const CatalogueProvider = ({children}) => {
    const [listingCatalogue, setListingCatalogue] = useState([]);

    /*CATALOGUE*/
    // useEffect(() => {
    //     getListingCatalogue().then(response => {
    //        console.log('asset_getListingCatalogue', response[0])
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    /*ISSUER ASSET*/

    // useEffect(() => {
    //     asset_getMyActiveListings().then(response => {
    //         console.log('asset_getMyActiveListings', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    // useEffect(() => {
    //     asset_getByEntryId().then(response => {
    //         console.log('asset_getByEntryId', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    // useEffect(() => {
    //     asset_Add().then(response => {
    //         console.log('asset_Add', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    // useEffect(() => {
    //     asset_Update().then(response => {
    //         console.log('asset_Update', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    /*ISSUER ASSET DETAILS */
    // useEffect(() => {
    //     assetDetails_Add().then(response => {
    //         console.log('assetDetails_Add', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    // useEffect(() => {
    //     assetDetails_Update().then(response => {
    //         console.log('assetDetails_Update', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    // useEffect(() => {
    //     assetDetails_GetByEntryId().then(response => {
    //         console.log('assetDetails_GetByEntryId', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    /*MEDIA*/

    // useEffect(() => {
    //     media_getArtById().then(response => {
    //         console.log('media_getArtById', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    // useEffect(() => {
    //     media_getMediaById().then(response => {
    //         console.log('media_getMediaById', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    // useEffect(() => {
    //     media_addArt().then(response => {
    //         console.log('media_addArt', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    // useEffect(() => {
    //     media_updateArt().then(response => {
    //         console.log('media_updateArt', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    /*ROYALTY RIGHTS */

    // useEffect(() => {
    //     getByAssetDetailsId().then(response => {
    //         console.log('getByAssetDetailsId', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    // useEffect(() => {
    //     getSelectedByAssetDetails().then(response => {
    //         console.log('getSelectedByAssetDetails', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    // useEffect(() => {
    //     getByRightId().then(response => {
    //         console.log('getByRightId', response)
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },[]);

    const value = {listingCatalogue, setListingCatalogue};
    return <CatalogueContext.Provider value={value}>{children}</CatalogueContext.Provider>
}

export {CatalogueProvider, useCatalogue}