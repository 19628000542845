import styled, { createGlobalStyle } from "styled-components";

export const MainBox = styled.div((props) => ({
  padding: `${props.theme.spacers.pageY} ${props.theme.spacers.pageX}`,

  "@media(max-width: 1500px)": {
    padding: `${props.theme.spacers.pageY} clamp(20px,6vw,166px);`,
  },

  [`@media${props.theme.breakpoints.sm}`]: {
    padding: `22px;`,
  },
}));

const GlobalStyle = createGlobalStyle((props) => ({
  body: {
    padding: `0 0`,
    backgroundColor: props.theme.palette.gray["500"],
    fontFamily: props.theme.fontFamilies.jost,
  },
  "h1, h2, h3, h4, h5, h6": {
    fontWeight: 500,
    margin: 0,
  },

  h1: {
    fontWeight: 600,
    letterSpacing: "-1.728px",
    fontFamily: props.theme.fontFamilies.workSans,
    fontSize: props.theme.fontSizes.h1,
  },

  h2: {
    fontSize: props.theme.fontSizes.h2,
  },

  h3: {
    fontSize: props.theme.fontSizes.h3,
  },

  h4: {
    margin: `${props.theme.spacers["2"]} 0`,
    fontSize: props.theme.fontSizes.h4,
  },

  h5: {
    fontSize: props.theme.fontSizes.h5,
  },

  h6: {
    fontSize: props.theme.fontSizes.h6,
  },

  p: {
    fontSize: props.theme.fontSizes.p,
    margin: "5px",
  },

  div: {
    fontSize: props.theme.fontSizes.p,
  },

  a: {
    cursor: "pointer",
    color: props.theme.palette.blue.link,
  },

  ".dotted-border": {
    backgroundPosition: "bottom",
    backgroundRepeat: "repeat-x",
    backgroundSize: "10px 2px",
    backgroundImage:
      "linear-gradient(to right,#797676 10%,rgba(255,255,255,0) 0%)",
  },

  // '.MuiBox-root': {
  //     padding: '24px 0 !important',
  // },

  ".center-absolute": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  ".MuiFormControl-root": {
    "& input": {
      margin: 0,
      color: props.theme.palette.gray["900"],
      fontSize: props.theme.fontSizes.p,
      fontFamily: props.theme.fontFamilies.jost,
    },
  },

  ".required::after": {
    content: "'*'",
    margin: "0 10px",
    color: props.theme.palette.primary.main,
  },

  [`@media ${props.theme.breakpoints.lg}`]: {
    body: {
      padding: 0,
    },
  },
}));

export default GlobalStyle;
