const apiURL = process.env.REACT_APP_API_URL;
const fakeURL = process.env.REACT_APP_LOCAL_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

var tmpHeaders = {
  "Content-Type": "application/json",
  "Authorization": apiKey,
}

const myHeaders = new Headers(tmpHeaders);

export const setUpAPI = async (command, data, method='GET', parameters={}, headers=myHeaders) => {
    var url = apiURL;
  
    var requestOptions = {};
  
    if(command) {
      url = `${url}${command}`;
    }

    if(parameters) {
      url += `?`;

      Object.entries(parameters).map((entry, index) => {
        const [key, value] = entry;
        url += `${key}=${value}&`
      });

      url = url.substring(0, url.length-1);
    }
  
    if(method) {
      requestOptions.method = method;
    }
  
    if(headers) {
      requestOptions.headers = headers
    }
  
    if(data) {
      var raw = JSON.stringify(data);
      requestOptions.body = raw;
    }

    return fetch(url, requestOptions);
}