import React from "react";
import {
  CMFooterContainer,
  CMFooterCopyRight,
  CMFooter,
} from "./footer.styles";
import Socialmedia from "../../components/social-media/social-media.component";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <CMFooter>
        <CMFooterContainer>
          <div className="smallColumn">
            <div>
              <Link to="/auctions-board">Auctions board</Link>
            </div>
            <div>
              <Link to="/trading-board">Trading board</Link>
            </div>
            <div>
              <Link to="/portfolio">My portfolio</Link>
            </div>
            <div>
              <Link to="/dashboard">My Listings</Link>
            </div>
          </div>
          <div className="largeColumn">
            <div>Contact us</div>
            <div>Support center</div>
            <div>Latest news</div>
            <div>FAQs</div>
            <div>Privacy notice</div>
            <div>Disclaimer</div>
            <div>Website Terms and Conditions</div>
          </div>
          <div className="rightColumn">
            <Socialmedia style={{ fill: "#fff" }} hide={{ display: "none" }} />
          </div>
        </CMFooterContainer>
        <CMFooterCopyRight>
          Copyright &copy;2023 Music bull. All rights reserved.
        </CMFooterCopyRight>
      </CMFooter>
    </footer>
  );
};

export default Footer;
