import styled from "styled-components";

export const primaryImage = styled.img`
    border-radius: 0;
`;

export const RoundedImage = styled.img`
    border-radius: 50%;
`;

export const SemiroundedImage = styled.img`
    border-radius: 30%;
`;