import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../Contexts/auth.context";
import Button, { BUTTON_TYPE_CLASSES } from "../../button/button.component";
import Image from "../../image/image.component";
import MenuItem from "../menu-item/menu-item.component";
import { CMList, CMNav, CashSubmenuDiv } from "./menu.styles";
import { ReactComponent as MenuIcon } from "../../../icons/menu.svg";
import { ReactComponent as CloseIcon } from "../../../icons/close.svg";
import { useEffect, useState } from "react";
import { ReactComponent as AuctionIcon } from "../../../icons/Group 308.svg";
import { ReactComponent as TradingIcon } from "../../../icons/money-flow.svg";
import { ReactComponent as PortfolioIcon } from "../../../icons/portfolio (1).svg";
import { ReactComponent as AccountIcon } from "../../../icons/user (1).svg";
import { ReactComponent as SuccessIcon } from "../../../icons/list.svg";
import { ReactComponent as HistoryIcon } from "../../../icons/clock-history.svg";
import { ReactComponent as FundsIcon } from "../../../icons/top-up.svg";
import { ReactComponent as ListingIcon } from "../../../icons/list.svg";
import { ReactComponent as SupportIcon } from "../../../icons/info-circle.svg";
import { ReactComponent as LogoutIcon } from "../../../icons/chevron-bar-left.svg";
import { ReactComponent as BellIcon } from "../../../icons/bell.svg";
import { ReactComponent as WalletIcon } from "../../../icons/wallet.svg";
import { ReactComponent as UserIcon } from "../../../icons/Group 281.svg";
import { ReactComponent as ArrowIcon } from "../../../icons/close.svg";

const CashAvailableComponent = () => {
  return (
    <CashSubmenuDiv>
      {/* First row */}

      <div className="MyfundsRowLeft dotted-border">
        <h4>My Funds</h4>
      </div>
      <div className=" MyfundsRowRight dotted-border">
        <Button size="small" color="blue">
          Add Funds
        </Button>
      </div>

      {/* Second row */}
      <div className="textCenter">
        <h5>Cash</h5>
        <h5 className="textColorGray">$10,101</h5>
      </div>
      <div className="textCenter">
        <h5>Restricted Cash</h5>
        <h5 className="textColorGray">$20,817</h5>
      </div>
      <div className="billing-history">
        <h6>Billing History</h6>
      </div>
    </CashSubmenuDiv>
  );
};
const links = [
  {
    description: "Auction Board",
    link: "auctions-board",
    icon: AuctionIcon,
    hideDesktop: true,
    hideMobile: false,
  },
  {
    description: "Trading Board",
    link: "trading-board",
    icon: TradingIcon,
    hideDesktop: true,
    hideMobile: false,
  },
  {
    description: "My Portfolio",
    link: "portfolio",
    icon: PortfolioIcon,
    hideDesktop: true,
    hideMobile: false,
  },
  {
    description: "My Listings",
    link: "dashboard",
    icon: AuctionIcon,
    hideDesktop: true,
    hideMobile: true,
  },
  {
    description: "",
    link: "",
    icon: WalletIcon,
    hideDesktop: false,
    hideMobile: true,
    subMenu: [],
  },
  {
    description: "",
    link: "",
    icon: BellIcon,
    hideDesktop: false,
    hideMobile: true,
  },
  {
    description: "",
    link: "",
    icon: UserIcon,
    hideDesktop: false,
    hideMobile: false,
    subMenu: [
      {
        description: "Account",
        link: "/account",
        icon: AccountIcon,
      },
      {
        description: "My Listings",
        link: "/dashboard",
        icon: ListingIcon,
      },
      {
        description: "History",
        link: "",
        icon: HistoryIcon,
      },
      {
        description: "Add Funds",
        link: "",
        icon: FundsIcon,
      },
      {
        description: "Support",
        link: "",
        icon: SupportIcon,
      },
      {
        description: "Log Out",
        link: "",
        icon: LogoutIcon,
      },
    ],
  },
];

const Menu = () => {
  const { currentUser } = useAuth();
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const location = useLocation();
  const [showFixedHeader, setShowFixedHeader] = useState(false);

  const [showCashAvailable, setShowCashAvailable] = useState(false);

  const handleWalletIconMouseEnter = (link) => {
    if (link.icon === WalletIcon) {
      setShowCashAvailable(true);
    }
  };

  const handleWalletIconMouseLeave = (link) => {
    if (link.icon === WalletIcon) {
      setShowCashAvailable(false);
    }
  };

  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    setOpenMenu(false);
  }, [location.pathname]);
  useEffect(() => {
    // Update the selected menu based on the current location
    const pathname = location.pathname;
    const matchedMenu = links.find((linked) => pathname.includes(linked.link));
    setSelectedMenu(matchedMenu ? matchedMenu.description.toUpperCase() : null);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      // Set the desired scroll threshold value
      const scrollThreshold = 40;

      // Toggle the visibility of the fixed header based on the scroll position
      setShowFixedHeader(scrollTop > scrollThreshold);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CMNav>
      <Button
        buttonType={BUTTON_TYPE_CLASSES.TextButton}
        size="small"
        onClick={handleMenu}
      >
        {!openMenu ? (
          <MenuIcon width={24} height={24} />
        ) : (
          <CloseIcon width={24} height={24} />
        )}
      </Button>

      <h5>{selectedMenu}</h5>
      <BellIcon width={24} height={24} className="bellIconPadding" />

      {showFixedHeader && (
        <div className="scrollHeader">
          <Button
            buttonType={BUTTON_TYPE_CLASSES.TextButton}
            size="small"
            onClick={handleMenu}
          >
            {!openMenu ? (
              <MenuIcon width={24} height={24} />
            ) : (
              <CloseIcon width={24} height={24} />
            )}
          </Button>

          <h5 className="scrollViewHeading">{selectedMenu}</h5>

          <BellIcon width={24} height={24} className="fixedHeaderbellIcon" />
        </div>
      )}
      <Link to={""}>
        <Image src="logo.png" width="147px" />
      </Link>
      {currentUser && (
        <CMList open={openMenu} className={`${openMenu ? "opened" : ""}`}>
          <ArrowIcon
            width={16}
            height={16}
            className="close"
            onClick={handleMenu}
          />
          <Link to={""} className="mobile-logo dotted-border">
            <Image src="logo.png" width="147px" />
          </Link>

          <>
            {links.map((link, idx) => {
              const Icon = link.icon;
              console.log("link", link);
              return (
                <MenuItem
                  key={idx}
                  link={link}
                  className={`${link.hideDesktop ? "hide-lg" : ""}${
                    link.hideMobile ? " hide-sm" : ""
                  }${!link.hideDesktop && link.icon ? "icon-link" : ""}`}
                  onMouseEnter={() => handleWalletIconMouseEnter(link)}
                  onMouseLeave={() => handleWalletIconMouseLeave(link)}
                >
                  {/* Render the icon */}
                  {Icon && <Icon width={32} height={32} />}

                  {link.description}
                  {showCashAvailable && Icon === WalletIcon && (
                    <div className="cash-available-container">
                      <CashAvailableComponent />
                    </div>
                  )}
                </MenuItem>
              );
            })}
          </>
        </CMList>
      )}
    </CMNav>
  );
};

export default Menu;
