import { CMSocialMedia } from "./social-media.styles";
import { ReactComponent as FbIcon } from "../../icons/facebook.svg";
import { ReactComponent as SharingIcon } from "../../icons/sharing.svg";
import { ReactComponent as StarIcon } from "../../icons/star.svg";
import { ReactComponent as Twitter } from "../../icons/twitter2.svg";
import { ReactComponent as Tiktok } from "../../icons/tiktok.svg";
import { ReactComponent as Instagram } from "../../icons/instagram2.svg";
import { ReactComponent as FilledStarIcon } from "../../icons/star-filled.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Star } from "@mui/icons-material";

const SocialMedia = (props) => {
  const [filled, setFilled] = useState(false);

  const onClickStart = () => {
    setFilled((old) => !old);
    console.log(filled);
  };

  return (
    <CMSocialMedia {...props}>
      {filled ? (
        <FilledStarIcon onClick={onClickStart} style={props.hide} />
      ) : (
        <StarIcon onClick={onClickStart} style={props.hide} />
      )}

      <a href={"#"}>
        <FbIcon style={props.style} />
      </a>
      <a href={"#"}>
        <Twitter style={props.style} />
      </a>
      <a href={"#"}>
        <Instagram style={props.style} />
      </a>
      <a href={"#"}>
        <Tiktok style={props.style} />
      </a>
      <a href={"#"}>
        <SharingIcon style={props.style} />
      </a>
    </CMSocialMedia>
  );
};

export default SocialMedia;
