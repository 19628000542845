import { toAbsoluteUrl } from "../../Utils/assets.utils";
import { primaryImage, RoundedImage, SemiroundedImage } from "./image.styles";

export const IMAGE_TYPE = {
    primary: 'primary',
    rounded: 'rounded',
    semi: 'semi-rounded' 
}

const getImage = (imageType = IMAGE_TYPE.primary) => (
    {   [IMAGE_TYPE.primary]: primaryImage,
        [IMAGE_TYPE.rounded]: RoundedImage,
        [IMAGE_TYPE.semi]: SemiroundedImage
    }[imageType]
)

const Image = ({imageType, src, alt, ...props}) => {
    const CMImage = getImage(imageType);
    
    return <CMImage src={`${toAbsoluteUrl(src)}`} alt={alt} {...props} />
}

export default Image;