import styled from "styled-components";

export const CMFooter = styled.div((props) => ({
  height: 309,
  backgroundColor: "#000000",
  justifyContent: "space-between",
  color: "#fff",
  bottom: 0,
  left: 0,
  flex: 1,
  width: "100%",
  [`@media ${props.theme.breakpoints.lg}`]: {
    flexDirection: "column",
    height: "auto",
  },
  [`@media ${props.theme.breakpoints.xs}`]: {
    flexDirection: "column",
    height: "auto",
  },
  [`@media ${props.theme.breakpoints.md}`]: {
    flexDirection: "column",
    height: "auto",
  },
}));
export const CMFooterContainer = styled.div((props) => ({
  display: "flex",
  paddingLeft: 124,
  paddingTop: 54,
  "& a": {
    color: "#fff",
    textDecoration: "none",
  },
  " & .smallColumn": {
    flex: 1.1,
    fontFamily: "props.theme.fontFamilies.Jost",
    fontSize: 16,
    flexBasis: 0,
    fontWeight: "normal",
  },
  " & .largeColumn": {
    fontFamily: "props.theme.fontFamilies.Jost",
    fontSize: 16,
    fontWeight: "normal",
    flex: 2,
  },

  "& .rightColumn": {
    flex: 1,
    textAlign: "left",

    [`@media ${props.theme.breakpoints.lg}`]: {
      paddingTop: "4%",
    },
    [`@media ${props.theme.breakpoints.xs}`]: {
      paddingTop: "4%",
    },
    [`@media ${props.theme.breakpoints.md}`]: {
      paddingTop: "4%",
    },
  },
  [`@media ${props.theme.breakpoints.lg}`]: {
    paddingLeft: 80,
    flexDirection: "column",
    height: "auto",
  },
  [`@media ${props.theme.breakpoints.xs}`]: {
    flexDirection: "column",
    height: "auto",
  },
  [`@media ${props.theme.breakpoints.md}`]: {
    flexDirection: "column",
    height: "auto",
  },
  [`@media ${props.theme.breakpoints.xss}`]: {
    paddingLeft: 50,
  },
}));

export const CMFooterCopyRight = styled.div((props) => ({
  position: "relative",
  bottom: 0,
  width: "100%",
  textAlign: "left",
  color: "#fff",
  marginLeft: 124,
  fontFamily: "props.theme.fontFamilies.Jost",
  fontSize: 16,
  marginTop: 34,
  [`@media ${props.theme.breakpoints.lg}`]: {
    marginLeft: 80,
    marginTop: 14,
  },
  [`@media ${props.theme.breakpoints.xs}`]: {
    marginTop: 14,
  },
  [`@media ${props.theme.breakpoints.md}`]: {
    marginTop: 14,
  },
  [`@media ${props.theme.breakpoints.xss}`]: {
    marginLeft: 50,
  },
}));
