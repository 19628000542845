import parse from 'html-react-parser';

export const parseHTML = (input) =>
  parse(input, {
      replace: domNode => {
      if (domNode instanceof Element && domNode.attribs.class === 'remove') {
          return <></>;
      }
      }
});

export const formatCommaNumbers = (number) => {
    let formattedNumber = Number(number);

    if(!isNaN(formattedNumber)) {
        return formattedNumber.toLocaleString('en-US');
    } else {
        return number;
    }
}

export const formatCurrency = (number) => {
    return (number).toLocaleString('en-US', { 
		style: 'currency', 
		currency: 'USD' 
	});
}

export const getOrdinal = (n) => {
    let ord = 'th';
  
    if (n % 10 == 1 && n % 100 != 11)
    {
      ord = 'st';
    }
    else if (n % 10 == 2 && n % 100 != 12)
    {
      ord = 'nd';
    }
    else if (n % 10 == 3 && n % 100 != 13)
    {
      ord = 'rd';
    }
  
    return `${n}${ord}`;
}

export const formatNumberUnits = (num, precision = 1) => {
    const map = [
      { suffix: 'T', threshold: 1e12 },
      { suffix: 'B', threshold: 1e9 },
      { suffix: 'M', threshold: 1e6 },
      { suffix: 'K', threshold: 1e3 },
      { suffix: '', threshold: 1 },
    ];
  
    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const final = num / found.threshold;
      const fixed = final.toFixed(precision);

      if(fixed % 1 == 0) {
        return Math.round(fixed) + found.suffix;
      } else {
        return fixed + found.suffix;
      }
    }

    return Math.ceil(num*100)/100;
}

export const formatKey = (str) => {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const acceptCookieConsent = (cookie_name) => {
  deleteCookie(cookie_name);
  setCookie(cookie_name, 'dismiss', 30);
}

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

export const deleteCookie = (cname) => {
  const d = new Date();
  d.setTime(d.getTime() + (24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=;" + expires + ";path=/";
}