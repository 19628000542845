const theme = {
  palette: {
    primary: {
      main: "#a80000",
      mainHover: "#c54b4b",
      900: "#bd0000",
      500: "#ff4f6b",
      100: "#f4e0e0",
    },
    green: {
      900: "#008E2F",
      800: "#56b010",
      700: "#74c932",
      600: "#9dce78",
      500: "#bce399",
      400: "#f4f9f0",
    },
    blue: {
      link: "#4090cd",
      notifications: "#F1F7FA",
      900: "#006bbd",
      800: "#1f78b4",
      700: "#0397c5",
      600: "#329bc9",
      500: "#a6cee3",
      400: "#e4f0f5",
      300: "#f3f9fc",
    },
    purple: {
      900: "#8a8eff",
    },
    pink: {
      900: "#a777e6",
    },
    gray: {
      900: "#545353",
      800: "#b8b8b8",
      700: "#d5d5d5",
      600: "#f5f5f5",
      500: "#f8f8f8",
      100: "#fbfbfb",
    },
    dark: {
      900: "#000000",
      800: "#030303",
    },
  },
  fontSizes: {
    h1: "clamp(2.5rem, 0.75rem, 3rem)", //40px
    h2: "clamp(1.5rem, 3vw, 1.875rem)", //30px
    h3: "24px", //24px
    h4: "20px", //20px
    h5: "1rem", //16px
    h6: "0.875rem", //14px
    p: "1rem",
    error: "",
    caption: "12px",
    button: "1rem",
  },
  fontFamilies: {
    jost: "'Jost', sans-serif",
    workSans: "'Work Sans', sans-serif",
  },
  spacers: {
    1: "5px",
    2: "8px",
    3: "10px",
    4: "12px",
    5: "16px",
    6: "20px",
    7: "40px",
    8: "50px",
    pageX: "clamp(20px, 10vw, 166px)",
    pageY: "40px",
  },
  breakpoints: {
    xxl: "(max-width: 1280px)",
    xl: "(max-width: 1024px)",
    lg: "(max-width: 992px)",
    md: "(max-width: 768px)",
    sm: "(max-width: 575px)",
    xs: "(max-width: 480px)",
    xss: "(max-width: 479px)",
  },
  shadows: {},
  icons: {},
};

export default theme;
