import { Link, useLocation } from "react-router-dom";
import { React } from "react";
import styled from "styled-components";

const CMLi = styled.li`
  padding: 5px 1rem;
  list-style-type: none;
  transition: height 1s ease;
  position: relative;
  cursor: pointer;

  &:not(.hide-smicon-link):hover,
  &.active {
    & > a {
      border-bottom: solid 2px ${(props) => props.theme.palette.primary.main};
    }
  }

  a {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
  }

  svg {
    margin-bottom: -10px;
  }

  &.hide-lg {
    svg {
      display: none;
    }
  }

  &.submenu-container {
    &:hover {
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      background-color: white;
      box-shadow: 0px 5px 25px -5px;
      &::before {
        display: block;
      }

      & .submenu {
        display: block;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      width: 100%;
      height: 15px;
      background-color: white;
      display: none;
      left: 0;
    }

    & .submenu {
      right: 0;
      top: calc(100% + 15px);
      margin: 0;
      padding: 30px;
      width: calc(254px - 60px);
      position: absolute;
      background-color: white;
      border-radius: 14px;
      border-top-right-radius: 0;
      display: none;
      box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.1);
      z-index: 1;

      & li {
        padding-bottom: 15px;

        &.dotted-border {
          padding-top: 25px;
          background-position: top;
        }

        & a {
          display: block;
          padding-bottom: 10px;
          text-transform: capitalize;
          svg {
            margin-right: 24px;
          }
        }

        &:hover,
        &.active {
          & a {
            border: unset !important;
            color: ${(props) => props.theme.palette.primary.main};
          }
        }
      }
    }
  }
  @media${(props) => props.theme.breakpoints.xl} {
    svg {
      margin-bottom: -10px;
      margin-right: 0px;
    }
    padding: 5px 14px;
  }
  @media${(props) => props.theme.breakpoints.lg} {
    padding: 5px ${(props) => props.theme.spacers.pageX};

    &:hover,
    &.active {
      & > a {
        border-bottom: unset;
        color: ${(props) => props.theme.palette.primary.main};
      }
    }

    &.hide-lg {
      svg {
        display: inline-block;
      }
    }

    &.hide-sm {
      display: none;
    }

    &.submenu-container {
      padding: 0 20px !important;

      & > svg {
        display: none;
      }

      & .submenu {
        width: 100%;
        padding: 0;
        position: unset;
        display: block;

        & li {
          padding: 20px 0 !important;
        }
      }
    }

    & a {
      text-transform: capitalize;
    }
  }
`;

const MenuItem = ({ children, className, ...props }) => {
  const location = useLocation();
  const { link, subMenu } = props.link;
  console.log("link", props.link);
  return (
    <CMLi
      {...props}
      className={`${className}${
        link != "" && location.pathname.includes(link) ? " active" : ""
      }${subMenu ? " submenu-container" : ""}`}
    >
      {!subMenu ? (
        <Link to={link}>{children}</Link>
      ) : (
        <>
          {children}
          <ul className="submenu">
            {subMenu.map((subItem, idx) => {
              const Icon = subItem.icon;

              return (
                <MenuItem
                  key={idx}
                  link={subItem}
                  className={`${
                    idx === subMenu.length - 1 ? "dotted-border" : ""
                  }`}
                >
                  <Icon
                    width={32}
                    height={32}
                    className={`${subItem.hideDesktop ? "hide-lg" : ""}`}
                  />
                  {subItem.description}
                </MenuItem>
              );
            })}
          </ul>
        </>
      )}
    </CMLi>
  );
};

export default MenuItem;
