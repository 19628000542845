import styled from 'styled-components';

const handleColorType = (color, palette) => {
    switch(color) {
        case 'primary': 
            return palette.primary.main;
        case 'green': 
            return palette.green["900"];
        case 'light-green': 
            return '#74c932';
        case 'blue': 
            return palette.blue.link;
        case 'purple': 
            return palette.purple["900"];
        case 'pink': 
            return palette.pink["900"];
        case 'gray': 
            return palette.gray["800"];
        case 'black': 
            return palette.dark["900"];
        default:
            return palette.primary.main;
    }
}

const handleShapeType = (shape) => {
    switch(shape) {
        case 'squared': 
            return '0px';
        case 'semirounded': 
            return '8px';
        case 'rounded': 
            return '20px';
        default: 
            return '20px';
    }
}

const handleSizeType = (size) => {
    switch(size) {
        case 'small': 
            return 'auto';
        case 'medium': 
            return '120px';
        case 'large': 
            return '180px';
        default: 
            return '180px';
    }
}

export const BaseButton = styled.button(props => ({
    color: '#fff',
    cursor: 'pointer',
    marginTop: '1rem',
    padding: props.size && props.size === 'small' ? '5px 1.80rem' : '5px 1rem',
    textTransform: 'capitalize',
    fontSize: props.theme.fontSizes.button,
    fontFamily: props.theme.fontFamilies.jost,
    transition: 'all 0.5s ease',
    border: 'solid 1px transparent',
    textDecoration: 'unset',
    textAlign: 'center',
    display: 'inline-block',
    fontWeight: 400,
    height: 'auto',
    height: 'fit-content',
    
    minWidth: handleSizeType(props.size),
    borderRadius: handleShapeType(props.shape),
    backgroundColor: handleColorType(props.color, props.theme.palette),


    "&:hover": {
        color: handleColorType(props.color, props.theme.palette),
        borderColor: handleColorType(props.color, props.theme.palette),
        backgroundColor: 'white',
    },
    
}));

export const OutlinedButton = styled(BaseButton)(props => ({
    color: handleColorType(props.color, props.theme.palette),
    borderColor: handleColorType(props.color, props.theme.palette),
    backgroundColor: 'white',

    "&:hover": {
        color: 'white',
        borderColor: 'transparent',
        backgroundColor: handleColorType(props.color, props.theme.palette),
    }
}));

export const TextButton = styled(OutlinedButton)(props => ({
    borderColor: 'transparent',
    backgroundColor: 'unset',

    "&:hover": {
        borderColor: handleColorType(props.color, props.theme.palette),
        color: handleColorType(props.color, props.theme.palette),
        backgroundColor: 'unset',
    },
}));