import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import Menu from "../components/menu/menu/menu.component";
import Footer from "../components/footer/footer.component";
import CookiesDisclaimer from "../components/cookies-disclaimer/cookies-disclaimer.component";

const CMDiv = styled.div`
  padding: 40px 0;
  padding-bottom: 0px;
  min-height: calc(100vh - 120px);
  max-width: 1600px;
  margin: auto;
  position: relative;
  /*  border-radius: 15px; */
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-top-style: solid;
  border-color: #a80000;
  border-width: thick;
  @media ${(props) => props.theme.breakpoints.lg} {
    padding: 0 0;
  }
  @media ${(props) => props.theme.breakpoints.md} {
    padding: 0 0;
  }
  @media ${(props) => props.theme.breakpoints.sm} {
    padding: 0 0;
  }
`;

const bg = {
  backgroundImage: `url(${
    process.env.PUBLIC_URL + "/assets/back-internal-top.png"
  })`,
};

const MasterLayout = () => {
  const location = useLocation();
  const showMenu =
    !location.pathname.includes("new-listing") &&
    !location.pathname.includes("auctions-board/") &&
    !location.pathname.includes("trading-board/") &&
    !location.pathname.includes("auction-preview");
  return (
    <CMDiv
      style={
        showMenu ||
        location.pathname.includes("auction-preview") ||
        location.pathname.includes("auctions-board/") ||
        location.pathname.includes("trading-board/")
          ? bg
          : {}
      }
    >
      {showMenu ? <Menu /> : ""}
      <Outlet />
      <Footer />
      <CookiesDisclaimer/>
    </CMDiv>
  );
};

export default MasterLayout;
