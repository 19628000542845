import styled from "styled-components";

export const CMSocialMedia = styled.div((props) => ({
  "& svg": {
    width: "32px",
    height: "32px",
    cursor: "pointer",
    marginRight: props.theme.spacers["2"],
    fill: props.theme.palette.blue["600"],
  },
}));
