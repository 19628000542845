import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "../App";
import { useAuth } from "../Contexts/auth.context";
import { Suspense, lazy } from "react";
import Loading from "../components/loading/loading.component";

const {PUBLIC_URL} = process.env;

//LOADING ONLY WHEN IT'S NEEDED.
const AuthPage = lazy(() => import('./auth.routes'));
const ErrorPage = lazy(() => import('./error.routes'));
const PublicPage = lazy(() => import('./public.routes'));

const AppRoutes = () => {
  const {currentUser} = useAuth();
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      {/* DISPLAYS THE LOADING COMPONENT UNTIL THE ROUTES ARE READY */}
      <Suspense fallback={<Loading/>}>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorPage />} />
            {currentUser ? (
              <>
                <Route path='/*' element={<PublicPage />} />
                <Route index element={<Navigate to='/auctions-board' />} />
              </>
            ) : ( 
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route index element={<Navigate to='/auth' />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </> 
            )} 
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export {AppRoutes}
