import styled from "styled-components";

export const CMDisclaimer = styled.div((props) => ({
    left: 0,
    right: 0,
    bottom: 0,
    color: '#fff',
    display: 'flex',
    position: 'fixed',
    padding: '1em 1.8em',
    backgroundColor: '#000',
    justifyContent: 'space-between',

    '& P': {
        fontWeight: 400,
        fontSize: `calc(${props.theme.fontSizes.h4} - 3px)`,
    },

    '& a': {
        opacity: .8,
        color: '#fff',
        padding: '.2em',
        margin: '0 1rem',
        textDecoration: 'none',
        display: 'inline-block',

        '&:hover': {
            opacity: 1,
            textDecoration: 'underline',
        }
    },

    '& button': {
        margin: '0 1rem',
        color: '#000',
        fontWeight: 500,
        minWidth: '80px',
        padding: '.4em .8em',
        backgroundColor: '#f1d600',

        '&:hover': {
            color: '#000',
            border: 'unset',
            fontWeight: 700,
            backgroundColor: '#f1d600',
        }
    },

    [`@media ${props.theme.breakpoints.xs}`]: {
        flexDirection: 'column',

        '& button': {
            margin: '1rem 0',
        },
    }
}))