import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from './Contexts/auth.context';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AppRoutes } from './routing/app.routes';
import { ThemeProvider } from 'styled-components';
import theme from './theme/theme';
import GlobalStyle from './theme/global.styles';
import { CatalogueProvider } from './Contexts/catalogue.context';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
        <GlobalStyle/>
        <AuthProvider>
            <CatalogueProvider>
              <AppRoutes/>
            </CatalogueProvider>
        </AuthProvider>
      </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
