import styled, { css } from "styled-components";

export const CMList = styled.div`
  margin: 0;
  display: flex;
  padding-left: 0;
  flex-wrap: wrap;

  & .close {
    display: none;
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    top: 0;
    left: 0;
    width: 100%;
    max-width: 408px;
    z-index: 999;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    position: fixed;
    flex-direction: column;
    background-color: white;
    transition: all 0.25s ease;
    padding: 40px;
    flex-wrap: nowrap;
    transform: translateX(-100%);

    & .close {
      display: block;
    }

    &.opened {
      transform: translateX(0%);
    }

    .mobile-logo {
      margin: 0 auto;
    }

    li {
      padding: 20px !important;
    }

    ${(props) =>
      props.open &&
      css`
        opacity: 1;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
          rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        border-bottom: solid 1px ${(props) => props.theme.palette.gray["700"]};
      `}
  }
`;

export const CMActionsContainer = styled.div`

  justify-content: flex-start;
display:flex;
margin-top:10px;
margin-left:20px;
margin-right:20px;
flex-direct:"column";
  .cash-available-container {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .icon-link {
    position: relative;
  }

  .icon-link:hover .cash-available-container {
    display: block;
  }

  & button {
    width: 130px;
  
    padding: 5px 0;
  }

  
  }
  .billing-history {
    text-align: center;
    padding-top: 25;

     p  {
      cursor: pointer;
      color: ${(props) => props.theme.palette.blue.link};
      text-decoration: underline;
    },
  },
  @media ${(props) => props.theme.breakpoints.lg} {
    display: flex;
  }
`;

export const CMNav = styled.nav`
  display: flex;
  padding: 0 59px;
  align-items: flex-start;
  justify-content: space-between;
  & .bellIconPadding{
  display:none;
    }
    & .scrollHeader{
      display:none;
    }
  & h5 {
    display: none;
  }
  & > a {
    margin-top: -15px;
  }

  & > button {
    width: auto;
    display: none;
    cursor: pointer;
    min-width: unset;
    border: unset;

    :hover {
      background-color: unset;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .mobile-logo {
    width: 100%;
    height: auto;
    display: none;
    padding-bottom: 40px;
    text-align: center;

    img {
      width: 147px;
    }
  }
 
 
  @media ${(props) => props.theme.breakpoints.lg} {
    & .scrollHeader{
      display:block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background: #fff;
      z-index: 999;
      display: flex;
      align-items: center;
      height: 55px;
      border-top-style: solid;
      border-color: #a80000;
      border-width: thick;
      box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
      padding-right: 2%;
    }
  
    & .fixedHeaderbellIcon{
      padding-top:2px;
      margin-right:10px;
  }
    & .bellIconPadding{
      display:block;
      padding-top:14px;
    }
    & h5 {
      display: flex;
      padding-top: 17px;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    & .scrollViewHeading{
       
      padding-top: 5px;
      margin-left: 3%;
      margin-right: 7%;
      width: 80%;
     
    },
  
    position: relative;
    padding: 0 22px;

    & > button {
      padding: 0;
      display: block;
    
    }

    & > a {
      display: none;
    }

    .mobile-logo {
      display: block;
    }
  }
 
@media ${(props) => props.theme.breakpoints.md} {
  & .scrollViewHeading{
    padding-top: 5px !important;
    margin-right: 8% !important;
    width: 74%;
  }
}
  @media ${(props) => props.theme.breakpoints.sm} {
    & .scrollViewHeading{
    margin-left: 3% !important;
    margin-right: 9% !important;
    padding-top: 5px;
    width: 67%;
    }
}
@media ${(props) => props.theme.breakpoints.xs} {
  & .scrollViewHeading{
  margin-left: 2% !important;
  margin-right: 9% !important;
  padding-top: 5px;
  width: 63%;
  }
}
  }
`;

export const CashSubmenuDiv = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: #fff;
  width: 332px;
  height: 174px;
  box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 20px;
  padding-top: 3px;
  border-radius: 20px 0 20px 20px;

  .MyfundsRowLeft {
    padding-bottom: 10px;
    font-family: Work Sans;
    padding-top: 15px;
  }
  .MyfundsRowRight {
    padding-bottom: 10px;
    text-align: right;
    font-family: Work Sans;
  }
  .textCenter {
    padding-top: 4px;
    text-align: center;
    font-family: Work Sans;
    font-weight: 500;
    color: #000000;
    h5 {
      display: block;
    }
  }
  .billing-history {
    cursor: pointer;
    color: #4090cd;
    text-decoration: underline;
    font-family: jost;
    h6 {
      font-weight: 400;
    }
  }
  .textColorGray {
    color: #545353;
    font-family: jost;
    font-weight: 400;
  }
`;
